/**
 * Storyblok API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { sbApi } from '../helpers/storyblok'

    sbApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
    const sbApi = async (clientKey, instanceKey, endpoint, method, body) => {
        const options = {
          method: method ? method : 'GET',
          credentials: 'same-origin',
          mode: 'same-origin'
        };
      
        if (body) {
          let bodyString = body;
          if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
          }
      
          options.body = bodyString;
        }
      
        const parseJson = async response => {
          const text = await response.text();
          try {
            const json = JSON.parse(text);
            return json;
          } catch (err) {
            return text;
          }
        };
      
        return await fetch(
          `/.netlify/functions/storyblok?endpoint=${btoa(endpoint)}&client=${clientKey}&key=${instanceKey}`,
          options
        ).then(async res => ({ response: await parseJson(res), status: res.status }));
      }
      
      const getData = async (clientKey, instanceKey, endpoint) => {
        return await sbApi(clientKey, instanceKey, endpoint, 'GET');
      }
      
      const getAllData = async (clientKey, instanceKey, endpoint) => {
        return await sbApi(clientKey, instanceKey, endpoint, 'GETALL');
      }
      
      const dataTypes = () => {
          return [
            'Stories',
          ];
      }
      
      const transformData = (data, dataType) => {
        let object = {
            name: data.title,
            content: data.content,
            slug: data.title.toLowerCase().replaceAll(' ', '-')
        };
        console.log(data);
        switch(dataType) {
            case 'Stories':
                object = {
                    story: {
                        name: data.title,
                        content: {
                            component: "Matter_Portal_Import",
                            title: data.title,
                            content: data.content,
                            metaTitle: data.title,
                            metaDescription: data.metaDescription,
                            keywords: data.keywords
                        },
                        slug: data.title.toLowerCase().replaceAll(' ', '-')
                    }
                }
                break;
            default:
                break;
        }
      
        return object;
      }
      
      /**
       * Get Data from selected data type
       */
      
      const getDataByType = async (clientKey, instanceKey, dataType) => {
        switch(dataType) {
            case 'Stories':
                return await getAllData(clientKey, instanceKey, 'stories');
            default:
                return { response: null, status: 404 }
        }
      }
      
      /**
      * Update data in platform
      */
      
      const updateData = async (clientKey, instanceKey, dataType, data) => {
        let endpoint = '';
        let body = transformData(data, dataType);
        switch(dataType) {
            case 'Stories':
                endpoint = `stories`;
                break;
            default:
                endpoint = '';
                break;
        }
      
        if (endpoint !== '') {
            return await sbApi(clientKey, instanceKey, endpoint, 'POST', body);
        } else {
            return { response: null, status: 404 }
        }
      }
        
      export { 
        getData,
        dataTypes,
        getDataByType,
        updateData
      };
        