const parseJson = async response => {
    const text = await response.text();
    try {
        const json = JSON.parse(text);
        return json;
    } catch (err) {
        return text;
    }
};

/**
 * BigCommerce API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} clientKey      The client key to fetch blob data
 * @param   {String} instanceKey    The instance key to obtain BC keys from
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { bcApi } from '../helpers/bigcommerce'

    bcApi('clientKey', 'instanceKey', 'endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
const bcApi = async (clientKey, instanceKey, endpoint, method, body, version) => {
    const options = {
        method: method ? method : 'GET',
        credentials: 'same-origin',
        mode: 'same-origin'
    };

    if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
        bodyString = JSON.stringify(body);
        }

        options.body = bodyString;
    }

    const encodedEndpoint = btoa(endpoint);

    if (!(typeof fetch === 'undefined')) {
        return await fetch(
            `/.netlify/functions/bigcommerce?${version ? `v=${version}&` : ''}endpoint=${encodedEndpoint}&client=${clientKey}&key=${instanceKey}`,
        options
        ).then(async res => ({ response: await parseJson(res), status: res.status }));
    } else {
        return { response: null, status: 404 }
    }
}

const getData = async (clientKey, instanceKey, endpoint, method, body, version) => {
    return await bcApi(clientKey, instanceKey, endpoint, method, body, version);
}

const getAllData = async (clientKey, instanceKey, endpoint, method, body, version, page = 1, data = []) => {
    const result = await bcApi(clientKey, instanceKey, `${endpoint}?limit=250&page=${page}`, method, body, version);
    
    if (result.status === 200) {
        if (result.response.meta.pagination.total_pages > page) {
            return await getAllData(clientKey, instanceKey, endpoint, method, body, version, page + 1, [...data, ...result.response.data]);
        } else {
            return { response: [...data, ...result.response.data], status: 200 }
        }
    }
}

/**
 * Define Data Types
 * @returns {Array}
 */

const dataTypes = () => {
    return [
        'Products',
        'Categories',
        'Pages',
        'Blog'
    ];
}

const transformData = (data) => {
    const object = {
      title: data.title,
      body: data.content,
    };
  
    if ('keywords' in data) {
      object.meta_keywords = data.keywords;
    }
  
    // if ('metaTitle' in data) {
    //   object.? = data.metaTitle;
    // }
  
    if ('metaDescription' in data) {
      object.meta_description = data.metaDescription;
    }
  
    return object;
  }

/**
 * Get Data from selected data type
 */

const getDataByType = async (clientKey, instanceKey, dataType) => {
    switch(dataType) {
        case 'Products':
            return await getAllData(clientKey, instanceKey, 'catalog/products');
        case 'Categories':
            return await getAllData(clientKey, instanceKey, 'catalog/trees/categories');
        case 'Pages':
            return await getAllData(clientKey, instanceKey, 'content/pages');
        case 'Blog':
            return await getAllData(clientKey, instanceKey, 'blog/posts');
        default:
            return { response: null, status: 404 }
    }
}

/**
 * Update data in platform
 */

const updateData = async (clientKey, instanceKey, dataType, data) => {
    let endpoint = '';
    let body = transformData(data);
    let method = 'id' in data ? 'PUT' : 'POST';
    let version = 3;
    switch(dataType) {
        case 'Products':
            endpoint = `catalog/products${'id' in data ? `/${data.id}` : ''}`;
            body = [transformData(data)];
            break;
        case 'Categories':
            endpoint = 'catalog/trees/categories';
            break;
        case 'Pages':
            endpoint = `content/pages${'id' in data ? `/${data.id}` : ''}`;
            break;
        case 'Blog':
            endpoint = `blog/posts${'id' in data ? `/${data.id}` : ''}`;
            version = 2;
            break;
        default:
            endpoint = '';
            break;
    }

    if (endpoint !== '') {
        return await bcApi(clientKey, instanceKey, endpoint, method, body, version);
    } else {
        return { response: null, status: 404 }
    }
}

export { 
    getData,
    dataTypes,
    getDataByType,
    updateData
};
