import { useState, useEffect } from 'react';
// import { navigate } from 'gatsby'
// import { useRouter } from 'next/router'

/**
 * Get local / session / cookie storage information
 * 
 * Handles retrieving browser storage information functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoStorage } from '../helpers/general'

    infoStorage('theKey')
 */
function infoStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return {storage: 'sessionStorage', value: sessionValue};
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return {storage: 'localStorage', value: localValue};
      }
    }
  } catch (e) {
    infoCookie(key);
  }

  return null;
}

/**
 * Get cookie storage information
 * 
 * Handles browser storage information functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoCookie } from '../helpers/general'

    infoCookie('theKey')
 */
function infoCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return {storage: 'cookie', value: c.substring(name.length, c.length)};
      }
    }
    return null;
  }
}

/**
 * Get local / session / cookie storage
 * 
 * Handles retrieving browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getStorage } from '../helpers/general'

    getStorage('theKey')
 */
function getStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return sessionValue;
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return localValue;
      }
    }
  } catch (e) {
    getCookie(key);
  }

  return null;
}

/**
 * Get cookie storage
 * 
 * Handles browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getCookie } from '../helpers/general'

    getCookie('theKey')
 */
function getCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}

/**
 * Set local / session / cookie storage
 * 
 * Handles setting browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setStorage } from '../helpers/general'

    setStorage('theKey', 'theValue', true)
 */
function setStorage(key, value, expire) {
  try {
    // Use Local / Session storage
    if (typeof sessionStorage !== 'undefined' && expire) {
      sessionStorage.setItem(key, value);
    } else if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (e) {
    // Use Cookies
    setCookie(key, value, expire);
  }
}

/**
 * Set cookie storage
 * 
 * Handles setting browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setCookie } from '../helpers/general'

    setCookie('theKey', 'theValue', true)
 */
function setCookie(key, value, expire) {
  if (typeof document !== 'undefined') {
    let exDays = 365;
    if (expire) {
      exDays = 1;
    }
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  }
}

/**
 * Remove local / session / cookie storage
 * 
 * Handles removing browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeStorage } from '../helpers/general'

    removeStorage('theKey')
 */
function removeStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        sessionStorage.removeItem(key);
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    // Use Cookies
    removeCookie(key);
  }
}

/**
 * Remove cookie storage
 * 
 * Handles removing browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeCookie } from '../helpers/general'

    removeCookie('theKey')
 */
function removeCookie(key) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=;${expires};path=/`;
  }
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Validate strong password format
 * 
 * 
 * @param   {String} password  The password
 * 
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Validate phone format
 * 
 * Checks the provided phone number and validates its format
 * 
 * @param   {String} number  The phone number
 * 
    import { validatePhoneNumber } from '../helpers/general'

    validatePhoneNumber(number)
 */
function validatePhoneNumber(number) {
  const regex = /^[- +()]*[0-9][- +()0-9]*$/;

  return regex.test(number);
}

/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
    import { isNumeric } from '../helpers/general'

    isNumeric(value)
 */
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false // we only process strings and numbers! 
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * Is value an object
 * 
 * Determine whether variable is an object
 * 
 * @param {*} obj 
 *
    import { isObject } from '../helpers/general'

    isObject(obj)
 */
function isObject(obj) {
  return (Array.isArray(obj) === false && typeof obj === 'object')
}

/**
 * Format price
 * 
 * Format number into local currency output
 * 
 * @param {Number} amount 
 * @param {String} currency
 * @param {Boolean} appendZero
 *
    import { formatPrice } from '../helpers/general'

    formatPrice(value, "USD", true)
 */
function formatPrice(amount, currency = "AUD", appendZero = false) {
  let displayAmount = (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) || amount;
  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-AU'
      : 'en-AU';

  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  });
  let formattedPrice = formatObject.format(displayAmount);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    const currencyValue = formattedPriceParts.find(obj => obj.type === 'currency');
    const decimalValue = formattedPriceParts.find(obj => obj.type === 'fraction');
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  }
  
  return formattedPrice;
}

/**
 * Get ordinal for date number
 * 
 * Given a date number, this will return the suffix for a date
 * 
 * @param   {Number} n  The date
 * 
    import { dateOrdinal } from '../helpers/general'

    dateOrdinal(25)
 */
function dateOrdinal(n) {
  return n > 3 && n < 21
    ? 'th'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 3
    ? 'rd'
    : 'th';
}

/**
 * Get value from querystring
 *
 * @param {string} key The key of the value you're looking to retrieve
 * @returns {string|false} the value or false if it doesn't exist
 */
 function getQuerystringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);

  return value || false;
}

/**
 * Calculate read time
 *
 * Pass content to be counted and return a minute value for the read time length
 *
 * @param   {string} content The content to be counted
 *
 * import { readTime } from '../helpers/general'
 *
 * readTime(content)
 */
function readTime(content) {
  const foundImages = (content.match(/<img/g) || []).length;
  const text = content.replace(/(<([^>]+)>)/gi, '');
  const foundWords = text.split(' ').length;

  let secs = (foundWords / 275) * 60;
  let addSecs = 12;
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs;
    addSecs--;
    if (addSecs < 3) addSecs = 3;
  }
  const minutes = Math.ceil(secs / 60);

  return minutes;
}

/**
 * Capitalise first letter
 * 
 * Capitalise the first letter in the provided string
 * 
 * @param   {string} string   The string
 * 
 * import { ucFirst } from '../helpers/general'
 * 
 * ucFirst(string)
 * 
 * @returns {string} the capitalised string
 */
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getBase64(file) {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

/**
 * Window Dimensions
 *
 * Gets values from window object for the `useWindowDimensions` function
 *
 */
function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return { width: 0, height: 0 };
}

/**
 * Window Dimensions
 * 
 * Provide on the fly window dimensions
 * 
    import { useWindowDimensions } from '../helpers/general'

    const { width } = useWindowDimensions()
 */
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return;
  }, []);

  return windowDimensions;
}

/**
 * useScript
 *
 * Provides a way to include a script on the frontend
 * 
    import { useScript } from '../helpers/general'

    useScript('https://path.to/script', 'functionOrObject', functionOnceLoaded);
 */
function useScript(url, functionOrObject, callback) {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const existing = functionOrObject in window;
      const loading = window.scriptsLoading && window.scriptsLoading.indexOf(functionOrObject) > -1;
      if (!existing && !loading) {
        if (!('scriptsLoading' in window)) {
          window.scriptsLoading = [];
        }
        window.scriptsLoading.push(functionOrObject);
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => { 
          if (callback) {
            callback();
          }
        };

        return () => {
          document.body.removeChild(script);
        }
      }

      if (existing && callback) {
        callback();
      }
    }
  }, [url, functionOrObject, callback])
}

/**
 * Decode unicode characters
 * 
 * Provides a way to decode a string containing unicode characters for logic purposes
 * 
    import { decodeUnicode } from '../helpers/general'

    decodeUnicode(content);
 */
function decodeUnicode(text) {
  var map = {
      '&amp;': '&',
      '&#038;': "&",
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
      '&#8217;': "'",
      '&#8216;': "'",
      '&#8211;': "-",
      '&#8212;': "-",
      '&#8230;': "…",
      '&#8221;': '"',
      '&#8243;': '"'
  };
  // console.log("Decoding", text);
  return text.replace(/&[\w\d#]{2,5};/g, function(m) { return map[m]; });
}

export {
  infoStorage,
  infoCookie,
  getStorage,
  getCookie,
  setStorage,
  setCookie,
  removeStorage,
  removeCookie,
  validateEmail,
  validateStrongPassword,
  validatePhoneNumber,
  isNumeric,
  isObject,
  formatPrice,
  dateOrdinal,
  getQuerystringValue,
  readTime,
  ucFirst,
  getBase64,
  useWindowDimensions,
  useScript,
  decodeUnicode,
};
