import { get } from 'lodash';

/**
 * Fauna DB API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Relevance AI API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { faunaDbApi } from '@helpers/faunadb'

    faunaDbApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function faunaDbApi(endpoint, method, body, key, parseType = 'text') {
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        if (typeof body === 'object' && typeof body.entries === 'undefined') {
            bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }

    const parseJson = async response => {
        if (parseType === 'blob') {
            return await response.blob();
        } else if (parseType === 'arrayBuffer') {
            return await response.arrayBuffer();
        } else if (parseType === 'text') {
            const text = await response.text();
            try {
                const json = JSON.parse(text);
                return json;
            } catch (err) {
                return text;
            }
        }
    };

    return await fetch(
        `/.netlify/functions/faunadb?endpoint=${btoa(endpoint)}${key ? `&key=${key}` : ''}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Create Transaction
 * 
 * Log a transaction in Fauna DB
 *
    import { createTransaction } from '@helpers/faunadb'

    createTransaction(client, user, type, credits, module, summary, pi);
*/
async function createTransaction(client, user, type, credits, module, summary, pi) {
    const body = {
        client_key: client,
        user_email: user,
        type: type,
        credits: Number(credits),
        module: module,
        summary: summary,
        pi: pi
    };
    const transaction = await faunaDbApi(`createTransaction`, 'POST', body);

    // console.log(transaction);

    if (transaction.status === 200 && transaction.response) {
        return transaction.response;

    }

    return [];
}

/**
 * Get Clients Transactions
 * 
 * Fetch all clients transactions in date order
 *
    import { getClientTransactions } from '@helpers/faunadb'

    getClientTransactions(client);
*/
async function getClientTransactions(client) {
    const transactions = await faunaDbApi(`getClientTransactions`, 'GET', null, client);

    // console.log(transactions);

    if (transactions.status === 200 && transactions.response) {
        return get(transactions, 'response.data.data', []);

    }

    return [];
}

/**
 * Create Conversation
 * 
 * Log a conversation in Fauna DB
 *
    import { createConversation } from '@helpers/faunadb'

    createConversation(client, user, module, description, steps);
*/
async function createConversation(client, user, module, description, steps) {
    const body = {
        client_key: client,
        user_email: user,
        module: module,
        description: description,
        steps: steps
    };
    const conversation = await faunaDbApi(`createConversation`, 'POST', body);

    console.log(conversation);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;

    }

    return [];
}

/**
 * Update Conversation
 * 
 * Update a conversation in Fauna DB
 *
    import { updateConversation } from '@helpers/faunadb'

    updateConversation(id, steps);
*/
async function updateConversation(body) {

    const conversation = await faunaDbApi(`updateConversation`, 'POST', body);

    console.log(conversation);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;

    }

    return [];
}

/**
 * Delete Conversation
 * 
 * Delete a conversation in Fauna DB
 *
    import { deleteConversation } from '@helpers/faunadb'

    deleteConversation(id);
*/
async function deleteConversation(id) {
    const body = {
        id: id
    };
    const conversation = await faunaDbApi(`deleteConversation`, 'DELETE', body);

    console.log(conversation);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;

    }

    return [];
}

/**
 * Get Clients Conversations for a module
 * 
 * Fetch all clients conversations for a particular module in date order
 *
    import { getClientConversationsForModule } from '@helpers/faunadb'

    getClientConversationsForModule(client, module);
*/
async function getClientConversationsForModule(client, module) {
    const conversations = await faunaDbApi(`getClientConversationsForModule`, 'GET', null, `${client}|${module}`);

    // console.log(conversations);

    if (conversations.status === 200 && conversations.response) {
        return get(conversations, 'response.data.data', []);

    }

    return [];
}

export {
    createTransaction,
    getClientTransactions,
    createConversation,
    updateConversation,
    deleteConversation,
    getClientConversationsForModule
}