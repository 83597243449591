/**
 * Wordpress API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 2 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { wpApi } from '../helpers/wordpress'

    wpApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
const wpApi = async (clientKey, instanceKey, endpoint, method, body, version) => {
  const options = {
    method: method ? method : 'GET',
    credentials: 'same-origin',
    mode: 'same-origin'
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }

    options.body = bodyString;
  }

  const parseJson = async response => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      return text;
    }
  };

  return await fetch(
    `/.netlify/functions/wordpress?${version ? `v=${version}&` : ''}endpoint=${btoa(endpoint)}&client=${clientKey}&key=${instanceKey}`,
    options
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

const getData = async (clientKey, instanceKey, endpoint, method, body, version) => {
  return await wpApi(clientKey, instanceKey, endpoint, method, body, version);
}

const getAllData = async (clientKey, instanceKey, endpoint, method, body, version, page = 1, data = []) => {
  const result = await wpApi(clientKey, instanceKey, `${endpoint}?limit=250&page=${page}`, method, body, version);
  
  if (result.status === 200) {
      if (result.response.meta.pagination.total_pages > page) {
          return await getAllData(clientKey, instanceKey, endpoint, method, body, version, page + 1, [...data, ...result.response.data]);
      } else {
          return { response: [...data, ...result.response.data], status: 200 }
      }
  }
}

// const uploadMedia = async (clientKey, instanceKey, filename, data, type) => {
//   return wpApi(
//     clientKey, instanceKey, 
//     'uploadMedia',
//     'POST',
//     { filename: `${filename}`, data: data, type: type },
//     null,
//     'action'
//   ).then(
//     response => {
//       // console.log(response);
//       return response;
//     },
//     rejected => console.log(rejected)
//   );
// }

const dataTypes = () => {
    return [
      'Categories',
      'Pages',
      'Posts'
    ];
}

const transformData = (data) => {
  const object = {
    title: data.title,
    content: data.content,
    yoast_meta: {}
  };

  if ('keywords' in data) {
    object.yoast_meta.yoast_wpseo_metakeywords = data.keywords;
    object.yoast_meta.yoast_wpseo_focuskw = data.keywords.split(',')[0];
  }

  if ('metaTitle' in data) {
    object.yoast_meta.yoast_wpseo_title = data.metaTitle;
    object.yoast_meta['yoast_wpseo_opengraph-title'] = data.metaTitle;
    object.yoast_meta['yoast_wpseo_twitter-title'] = data.metaTitle;
  }

  if ('metaDescription' in data) {
    object.yoast_meta.yoast_wpseo_metadesc = data.metaDescription;
    object.yoast_meta['yoast_wpseo_opengraph-description'] = data.metaDescription;
    object.yoast_meta['yoast_wpseo_twitter-description'] = data.metaDescription;
  }

  return object;
}

/**
 * Get Data from selected data type
 */

const getDataByType = async (clientKey, instanceKey, dataType) => {
  switch(dataType) {
      case 'Categories':
          return await getAllData(clientKey, instanceKey, 'categories');
      case 'Pages':
          return await getAllData(clientKey, instanceKey, 'pages');
      case 'Posts':
          return await getAllData(clientKey, instanceKey, 'posts');
      default:
          return { response: null, status: 404 }
  }
}

/**
* Update data in platform
*/

const updateData = async (clientKey, instanceKey, dataType, data) => {
  let endpoint = '';
  let body = transformData(data);
  switch(dataType) {
      case 'Categories':
          endpoint = 'categories';
          break;
      case 'Pages':
          endpoint = 'pages';
          break;
      case 'Posts':
          endpoint = 'posts';
          break;
      default:
          endpoint = '';
          break;
  }

  if (endpoint !== '') {
      return await wpApi(clientKey, instanceKey, endpoint, 'POST', body);
  } else {
      return { response: null, status: 404 }
  }
}
  
export { 
  getData,
  dataTypes,
  getDataByType,
  updateData
};
  