exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-image-scraper-index-js": () => import("./../../../src/pages/ai-image-scraper/index.js" /* webpackChunkName: "component---src-pages-ai-image-scraper-index-js" */),
  "component---src-pages-build-index-js": () => import("./../../../src/pages/build/index.js" /* webpackChunkName: "component---src-pages-build-index-js" */),
  "component---src-pages-bulk-meta-optimiser-index-js": () => import("./../../../src/pages/bulk-meta-optimiser/index.js" /* webpackChunkName: "component---src-pages-bulk-meta-optimiser-index-js" */),
  "component---src-pages-clients-index-js": () => import("./../../../src/pages/clients/index.js" /* webpackChunkName: "component---src-pages-clients-index-js" */),
  "component---src-pages-content-scorer-index-js": () => import("./../../../src/pages/content-scorer/index.js" /* webpackChunkName: "component---src-pages-content-scorer-index-js" */),
  "component---src-pages-content-writer-index-js": () => import("./../../../src/pages/content-writer/index.js" /* webpackChunkName: "component---src-pages-content-writer-index-js" */),
  "component---src-pages-discovery-index-js": () => import("./../../../src/pages/discovery/index.js" /* webpackChunkName: "component---src-pages-discovery-index-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-image-alt-text-generator-index-js": () => import("./../../../src/pages/image-alt-text-generator/index.js" /* webpackChunkName: "component---src-pages-image-alt-text-generator-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-product-writer-index-js": () => import("./../../../src/pages/product-writer/index.js" /* webpackChunkName: "component---src-pages-product-writer-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-redirects-index-js": () => import("./../../../src/pages/redirects/index.js" /* webpackChunkName: "component---src-pages-redirects-index-js" */),
  "component---src-pages-tasks-index-js": () => import("./../../../src/pages/tasks/index.js" /* webpackChunkName: "component---src-pages-tasks-index-js" */)
}

