/**
 * Stripe API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Stripe API endpoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { stripeApi } from '../helpers/stripe'

    stripeApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function stripeApi(endpoint, method, body, parseType = 'text') {
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        if (typeof body === 'object' && typeof body.entries === 'undefined') {
            bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }
    
    const parseJson = async response => {
        if (parseType === 'blob') {
            return await response.blob();
        } else if (parseType === 'arrayBuffer') {
            return await response.arrayBuffer();
        } else if (parseType === 'text') {
            const text = await response.text();
            try {
                const json = JSON.parse(text);
                return json;
            } catch (err) {
                return text;
            }
        }
    };
    
    return await fetch(
        `/.netlify/functions/stripe?endpoint=${btoa(endpoint)}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Create Customer
 * 
 * Send a request to create a customer record and return the customer ID to store against the user
 *
    import { createCustomer } from '../helpers/stripe'

    createCustomer(email, name, metadata);
*/
async function createCustomer(email, name, metadata) {
    const crcu = await stripeApi(`crcu`, 'POST', {email, name, metadata});

    // console.log(crcu);

    if (crcu.status === 200 && crcu.response) {
        return crcu.response;

    }

    return null;
}

/**
 * Get Customer record
 * 
 * Send a request to get a customer record and return the customer object
 *
    import { getCustomer } from '../helpers/stripe'

    getCustomer(id);
*/
async function getCustomer(id) {
    const gcu = await stripeApi(`gcu`, 'POST', {id});

    // console.log(gcu);

    if (gcu.status === 200 && gcu.response) {
        return gcu.response;

    }

    return {};
}

/**
 * Search Customer
 * 
 * Send a request to search a customer record by email and return the customer object
 *
    import { searchCustomer } from '../helpers/stripe'

    searchCustomer(email);
*/
async function searchCustomer(email) {
    const scu = await stripeApi(`scu`, 'POST', {email});

    // console.log(scu);

    if (scu.status === 200 && scu.response) {
        return scu.response;

    }

    return {};
}
    
/**
 * Create Payment Intent
 * 
 * Send a request to return the client secret for the payment intent
 *
    import { payment } from '../helpers/stripe'

    payment(amount, customerId, metadata);
*/
async function payment(amount, customerId, metadata) {
    const cpi = await stripeApi(`cpi`, 'POST', {amount, customerId, metadata});

    // console.log(cpi);

    if (cpi.status === 200 && cpi.response) {
        return cpi.response;

    }

    return [];
}

/**
 * Confirm Payment Intent
 * 
 * Send a request to return the payment details for the payment intent
 *
    import { confirmPayment } from '../helpers/stripe'

    confirmPayment(intentId);
*/
async function confirmPayment(intentId) {
    const ci = await stripeApi(`ci`, 'POST', {intentId});

    // console.log(ci);

    if (ci.status === 200 && ci.response) {
        return ci.response;

    }

    return [];
}

/**
 * Create Setup Intent
 * 
 * Send a request to return the client secret for the setup intent
 *
    import { intent } from '../helpers/stripe'

    intent(customerId, metadata);
*/
async function intent(customerId, metadata) {
    const csi = await stripeApi(`csi`, 'POST', {customerId, metadata});

    // console.log(cpi);

    if (csi.status === 200 && csi.response) {
        return csi.response;

    }

    return [];
}

/**
 * Confirm Setup Intent
 * 
 * Send a request to return the intent details for the setup intent
 *
    import { confirmIntent } from '../helpers/stripe'

    confirmIntent(intentId);
*/
async function confirmIntent(client, intentId) {
    const cai = await stripeApi(`cai`, 'POST', {client, intentId});

    // console.log(cai);

    if (cai.status === 200 && cai.response) {
        return cai.response;

    }

    return [];
}
    
export {
    createCustomer,
    getCustomer,
    searchCustomer,
    payment,
    confirmPayment,
    intent,
    confirmIntent
}