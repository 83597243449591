/**
 * Atlassian API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Atlassian API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { atlassianApi } from '../helpers/atlassian'

    atlassianApi('confluence', 'endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function atlassianApi(app, endpoint, method, body, parseType = 'text') {
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        if (typeof body === 'object' && typeof body.entries === 'undefined') {
            bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }

    const parseJson = async response => {
        if (parseType === 'blob') {
            return await response.blob();
        } else if (parseType === 'arrayBuffer') {
            return await response.arrayBuffer();
        } else if (parseType === 'text') {
            const text = await response.text();
            try {
                const json = JSON.parse(text);
                return json;
            } catch (err) {
                return text;
            }
        }
    };

    return await fetch(
        `/.netlify/functions/atlassian?app=${app}&endpoint=${btoa(endpoint)}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Get News
 * 
 * Fetch JAMM news from Confluence
 *
    import { getNews } from '../helpers/atlassian'

    getNews();
 */
async function getNews() {
    // TODO; Work on getting env variables in here
    const newsId = process.env.ATLASSIAN_CONF_NEWS_ID || '2169864213';
    const news = await atlassianApi('confluence', `content/${newsId}/child/page?expand=body.view,history`);
    const newsReturn = [];

    console.log(news);

    // TODO: Need to deal with ordering
    if (news.status === 200 && news.response && news.response.results.length > 0) {
        news.response.results.map(newsItem => {
            newsReturn.push({
                title: newsItem.title,
                body: newsItem.body.view.value,
                date: newsItem.history.createdDate,
                author: newsItem.history.createdBy.publicName
            });

            return true;
        })
    }

    return newsReturn;
}

/**
 * Get Documentation
 * 
 * Fetch client documentation for the client from Confluence
 *
    import { getDocs } from '../helpers/atlassian'

    getDocs(docsId);
 */
async function getDocs(docsId) {
    const docs = await atlassianApi('confluence', `content/${docsId}/child/page?expand=body.view,history.lastUpdated`);
    const docsReturn = [];

    console.log(docs);

    if (docs.status === 200 && docs.response && docs.response.results?.length > 0) {
        docs.response.results.map(docsItem => {
            docsReturn.push({
                id: docsItem.id,
                title: docsItem.title,
                body: docsItem.body.view.value,
                lastUpdated: docsItem.history.lastUpdated
            });

            return true;
        })
    }

    return docsReturn;
}

/**
 * Get Projects
 * 
 * Fetch JIRA projects
 *
    import { getProjects } from '../helpers/atlassian'

    getProjects();
 */
async function getProjects() {
    const projects = await atlassianApi('jira', `3/project?expand=issueTypes,lead`);
    const projectsReturn = [];

    // console.log(projects);

    if (projects.status === 200 && projects.response && projects.response.length > 0) {
        projects.response.map(project => {
            if (!project.archived) {
                projectsReturn.push({
                    name: project.name,
                    id: project.id,
                    issueTypes: project.issueTypes,
                    lead: project.lead.accountId
                });
            }

            return true;
        });

    }

    return projectsReturn.sort((a, b) => a.name > b.name ? 1 : -1);
}

/**
 * Get Project Statuses
 * 
 * Fetch JIRA projects
 *
    import { getProjectStatuses } from '../helpers/atlassian'

    getProjectStatuses(projectId);
 */
async function getProjectStatuses(projectId) {
    const projectStatuses = await atlassianApi('jira', `3/project/${projectId}/statuses`);
    const statusesReturn = [];

    // console.log(projectStatuses);

    if (projectStatuses.status === 200 && projectStatuses.response && projectStatuses.response.length > 0) {
        projectStatuses.response.map(projectIssueType => {
            const statuses = projectIssueType.statuses;
            statuses.map(s => {
                if (statusesReturn.indexOf(s.name) === -1) {
                    statusesReturn.push(s.name);
                }
                return true;
            })

            return true;
        });

    }

    return statusesReturn.sort((a, b) => a > b ? 1 : -1);
}

/**
 * Get Project issue types
 * 
 * Fetch JIRA issue types for a project
 *
    import { getIssueTypes } from '../helpers/atlassian'

    getIssueTypes(projectId);
 */
async function getIssueTypes(projectId) {
    const issueTypes = await atlassianApi('jira', `3/issuetype/project?projectId=${projectId}`);
    const issueTypesReturn = [];

    // console.log(issueTypes);

    if (issueTypes.status === 200 && issueTypes.response && issueTypes.response.length > 0) {
        issueTypes.response.map(issueType => {
            if (issueType.hierarchyLevel === 0 && !issueType.subtask) {
                issueTypesReturn.push({
                    name: issueType.name,
                    id: issueType.id
                });
            }

            return true;
        });

    }

    return issueTypesReturn.sort((a, b) => a.name > b.name ? 1 : -1);
}

/**
 * Get Project tasks
 * 
 * Fetch JIRA tasks for a project
 *
    import { getTasks } from '../helpers/atlassian'

    getTasks(projectId);
 */
async function getTasks(projectId, page = 1, limit = 50, filters = {}) {
    const start = (page - 1) * limit;
    let jql = `project="${projectId}"`;
    Object.keys(filters).forEach(f => {
        if (filters[f] !== '') {
            let operator = "=";
            if (f === 'text') {
                operator = "~";
            }
            jql += `%20and%20${f}${operator}"${filters[f]}"`;
        }
    });
    const tasks = await atlassianApi('jira', `3/search?fields=assignee,status,summary&jql=${jql}&startAt=${start}&maxResults=${limit}`);
    const tasksReturn = [];

    // console.log(tasks);

    if (tasks.status === 200 && tasks.response && tasks.response.issues?.length > 0) {
        tasks.response.issues.map(task => {
            // if (task.criteria ?) {
            tasksReturn.push({
                name: `${task.key} | ${task.fields.summary}`,
                id: task.id,
                assignee: task.fields.assignee?.displayName || 'Unassigned',
                status: task.fields.status.name
            });
            // }

            return true;
        });

    }

    return { tasks: tasksReturn, total: tasks.response.total, maxPages: Math.ceil(tasks.response.total / limit), page };
}

/**
 * Get Project task
 * 
 * Fetch JIRA task for a project
 *
    import { getTask } from '../helpers/atlassian'

    getTask(issueId);
 */
async function getTask(issueId) {
    const issue = await atlassianApi('jira', `3/issue/${issueId}`);

    // console.log(issue);

    if (issue.status === 200 && issue.response) {
        return issue.response;

    }

    return {};
}

/**
 * Create Project task
 * 
 * Create JIRA task for a project
 *
    import { createTask } from '../helpers/atlassian'

    createTask(projectId, issueTypeId, fields);
 */
async function createTask(projectId, issueTypeId, fields) {
    const data = {
        "fields": {
            "description": {
                "content": [
                    {
                        "content": [
                            {
                                "text": fields.description,
                                "type": "text"
                            }
                        ],
                        "type": "paragraph"
                    }
                ],
                "type": "doc",
                "version": 1
            },
            "project": {
                "id": projectId
            },
            "issuetype": {
                "id": issueTypeId
            },
            "summary": fields.summary
        }
    };
    const issue = await atlassianApi('jira', `3/issue`, 'POST', data);

    // console.log(issue);

    if (issue.status === 200 && issue.response) {
        const mediaUploads = [];

        if (fields.media) {
            fields.media.map(file => {
                mediaUploads.push(createAttachment(issue.response.id, file));
                return true;
            });
        }

        await Promise.all(mediaUploads);
        return issue.response;
    }

    return {};
}

/**
 * Add comment to Project task
 * 
 * Create comment on a JIRA task for a project
 *
    import { createComment } from '../helpers/atlassian'

    createComment(issueId, fields);
 */
async function createComment(issueId, fields) {
    const content = Object.keys(fields).map(field => {
        switch (field) {
            case 'comment':
                return {
                    "content": [
                        {
                            "text": fields.comment,
                            "type": "text"
                        }
                    ],
                    "type": "paragraph"
                }

            case 'media':
                return {
                    "content": fields.media.map(file => ({
                        "type": "media",
                        "attrs": {
                            "type": "file",
                            "id": file.hash,
                            "collection": "comment files"
                        }
                    })),
                    "type": "mediaGroup"
                }

            default:
                return false;
        }
    }).filter(f => f);

    const data = {
        "body": {
            "content": content,
            "type": "doc",
            "version": 1
        },
    };
    const comment = await atlassianApi('jira', `3/issue/${issueId}/comment`, 'POST', data);

    // console.log(comment);

    if (comment.status === 200 && comment.response) {
        return comment.response;

    }

    return {};
}

/**
 * Add attachment to project task
 * 
 * Upload an attachment to a project task
 *
    import { createAttachment } from '../helpers/atlassian'

    createAttachment(issueId, filePath);
 */
async function createAttachment(issueId, filePath) {
    const formData = new FormData();
    formData.append('file', filePath);
    const attachment = await atlassianApi('jira', `3/issue/${issueId}/attachments`, 'POST', formData);

    if (attachment.status === 200 && attachment.response) {
        // console.log(attachment.response);
        const fileData = attachment.response[0];
        // console.log(fileData);
        const fileObject = await atlassianApi('jira', `3/attachment/content/${fileData.id}`);
        // console.log(fileObject);
        const realPath = fileObject.response;
        const realPathParts = realPath.split('/');
        fileData.realId = realPathParts[2];
        return fileData;
    }

    return {};
}

/**
 * Delete attachment from project task
 * 
 * Delete an attachment from a project task
 *
    import { deleteAttachment } from '../helpers/atlassian'

    deleteAttachment(fileId);
 */
async function deleteAttachment(fileId) {
    const attachment = await atlassianApi('jira', `3/attachment/${fileId}`, 'DELETE');

    if (attachment.status === 200 && attachment.response) {
        return attachment.response;
    }

    return {};
}

/**
 * Get JIRA active users
 * 
 * Get list of JIRA active users, filtering out any app or customer accounts
 *
    import { getUsers } from '../helpers/atlassian'

    getUsers();
 */
async function getUsers() {
    const users = await atlassianApi('jira', `3/user/search?query=*&maxResults=2500`);

    // console.log(users);

    if (users.status === 200 && users.response) {
        return users.response.filter(u => u.accountType === 'atlassian').sort((a, b) => a.displayName > b.displayName ? 1 : -1);

    }

    return [];
}

export {
    atlassianApi,
    getNews,
    getDocs,
    getProjects,
    getProjectStatuses,
    getIssueTypes,
    getTasks,
    getTask,
    createTask,
    createComment,
    createAttachment,
    deleteAttachment,
    getUsers
}