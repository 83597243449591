/**
 * Relevance AI API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Relevance AI API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { relevanceAiApi } from '../helpers/relevanceai'

    relevanceAiApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function relevanceAiApi(endpoint, method, body, parseType = 'text') {
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        if (typeof body === 'object' && typeof body.entries === 'undefined') {
            bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }

    const parseJson = async response => {
        if (parseType === 'blob') {
            return await response.blob();
        } else if (parseType === 'arrayBuffer') {
            return await response.arrayBuffer();
        } else if (parseType === 'text') {
            const text = await response.text();
            try {
                const json = JSON.parse(text);
                return json;
            } catch (err) {
                return text;
            }
        }
    };

    return await fetch(
        `/.netlify/functions/relevanceai?endpoint=${btoa(endpoint)}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Trigger Agent
 * 
 * Send a request to an agent to start a new conversation
 *
    import { trigger } from '../helpers/relevanceai'

    trigger(agentId, message);
*/
async function trigger(agentId, message, conversationId = null) {
    const body = { agent_id: agentId, message: { role: "user", content: message } };
    if (conversationId) {
        body.conversation_id = conversationId;
    }
    const conversation = await relevanceAiApi(`agents/trigger`, 'POST', body);

    // console.log(conversation);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;

    }

    return [];
}

/**
 * Read Conversation
 * 
 * Send a request to read a prior conversation
 *
    import { read } from '../helpers/relevanceai'

    read(conversationId);
*/
async function read(conversationId = null) {
    const body = { knowledge_set: conversationId, page_size: 20, sort: [{ insert_date_: "desc" }] };
    const conversation = await relevanceAiApi(`knowledge/list`, 'POST', body);

    // console.log(conversation);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;

    }

    return [];
}

/**
 * Run tool
 * 
 * Send a request to a tool to return specific data
 *
    import { tool } from '../helpers/relevanceai'

    tool(toolId, object);
*/
async function tool(toolId, object) {
    const body = object;
    const conversation = await relevanceAiApi(`studios/${toolId}/trigger_limited`, 'POST', body);

    // console.log(conversation);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;

    }

    return [];
}

/**
 * Make async call for long running tool
 * 
 * Send a request to a tool to return specific data
 *
    import { asyncTool } from '../helpers/relevanceai'

    asyncTool(toolId, object);
*/
async function asyncTool(toolId, object) {
    const body = object;

    const conversation = await relevanceAiApi(`studios/${toolId}/trigger_async`, 'POST', body);

    if (conversation.status === 200 && conversation.response) {
        return conversation.response;
    }

    return [];
}

/**
 * Check Agent
 * 
 * Send a request to check the response from an agent based on a started conversation
 *
    import { check } from '../helpers/relevanceai'

    check(studioId, jobId);
*/
async function check(studioId, jobId) {
    const status = await relevanceAiApi(`studios/${studioId}/async_poll/${jobId}`);

    // console.log(status);

    if (status.status === 200 && status.response) {
        return status.response;

    }

    return [];
}

/**
 * Calculate cost
 * 
 * Iterate through credits used to define a total for the call
 *
    import { calcCost } from '../helpers/relevanceai'

    calcCost(costObject);
*/
function calcCost(costObject) {
    return costObject.reduce((total, arr) => total + arr.credits, 0);
}

export {
    relevanceAiApi,
    trigger,
    read,
    tool,
    asyncTool,
    check,
    calcCost
}