/**
 * Blobs API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The Redirects API endpoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { blobsApi } from '@helpers/blobs'

    blobsApi('redirects', 'client', 'save', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function blobsApi(subject, client, action, method, body, parseType = 'text') {
    const options = {
        method: method ? method : 'GET'
    };
    
    if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }
    
    const parseJson = async response => {
        if (parseType === 'blob') {
            return await response.blob();
        } else if (parseType === 'arrayBuffer') {
            return await response.arrayBuffer();
        } else if (parseType === 'text') {
            const text = await response.text();
            try {
                const json = JSON.parse(text);
                return json;
            } catch (err) {
                return text;
            }
        }
    };

    return await fetch(
        `/datastore/${subject}/${client}/${action}/`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

function returnResult(response) {
    return {response: response.response.body, status: response.status};
}

/**
 * Get all client
 * 
 * Fetch client object in Netlify Blobs
 *
    import { getClients } from '@helpers/blobs'

    getClients();
 */
async function getClients() {
    const response = await blobsApi('clients', 'all', 'getAll', 'GET');

    // console.log(response);

    return returnResult(response);
}

/**
 * Create client
 * 
 * Create client object in Netlify Blobs
 *
    import { createClient } from '@helpers/blobs'

    createClient(client, object);
 */
async function createClient(client, data) {
    console.log(data);
    const response = await blobsApi('clients', client, 'create', 'POST', data);

    // console.log(response);

    return returnResult(response);
}

/**
 * Get client
 * 
 * Fetch client object in Netlify Blobs
 *
    import { getClient } from '@helpers/blobs'

    getClient(client);
 */
async function getClient(client) {
    const response = await blobsApi('clients', client, 'get', 'GET');

    // console.log(response);

    return returnResult(response);
}

/**
 * Update client
 * 
 * Update client object in Netlify Blobs
 *
    import { updateClient } from '@helpers/blobs'

    updateClient(client, object);
 */
async function updateClient(client, data) {
    const response = await blobsApi('clients', client, 'update', 'PUT', data);

    // console.log(response);

    return returnResult(response);
}

/**
 * Delete client
 * 
 * Delete client object in Netlify Blobs
 *
    import { deleteClient } from '@helpers/blobs'

    deleteClient(client);
 */
async function deleteClient(client) {
    const response = await blobsApi('clients', client, 'delete', 'DELETE');

    // console.log(response);

    return returnResult(response);
}

/**
 * Save settings
 * 
 * Store settings object in Netlify Blobs
 *
    import { saveSettings } from '@helpers/blobs'

    saveSettings(client, object);
 */
async function saveSettings(client, settings) {
    const response = await blobsApi('settings', client, 'save', 'POST', settings);

    // console.log(response);

    return returnResult(response);
}

/**
 * Get settings
 * 
 * Fetch settings object in Netlify Blobs
 *
    import { getSettings } from '@helpers/blobs'

    getSettings(client, object);
 */
async function getSettings(client) {
    const response = await blobsApi('settings', client, 'get', 'GET');

    // console.log(response);

    return returnResult(response);
}
    
/**
 * Save redirects
 * 
 * Store redirects object in Netlify Blobs
 *
    import { saveRedirects } from '@helpers/blobs'

    saveRedirects(client, object);
 */
async function saveRedirects(client, redirects) {
    const response = await blobsApi('redirects', client, 'save', 'POST', redirects);

    // console.log(response);

    return returnResult(response);
}

/**
 * Get redirects
 * 
 * Fetch redirects object in Netlify Blobs
 *
    import { getRedirects } from '@helpers/blobs'

    getRedirects(client, object);
 */
async function getRedirects(client) {
    const response = await blobsApi('redirects', client, 'get', 'GET');

    // console.log(response);

    return returnResult(response);
}

/**
 * Save profile
 * 
 * Store profile object in Netlify Blobs
 *
    import { saveProfile } from '@helpers/blobs'

    saveProfile(client, object);
 */
async function saveProfile(client, details) {
    const response = await blobsApi('profile', client, 'save', 'POST', details);

    // console.log(response);

    return returnResult(response);
}

/**
 * Get profile
 * 
 * Fetch profile object in Netlify Blobs
 *
    import { getProfile } from '@helpers/blobs'

    getProfile(client, object);
    */
async function getProfile(client) {
    const response = await blobsApi('profile', client, 'get', 'GET');

    // console.log(response);

    return returnResult(response);
}
    
export {
    blobsApi,
    getClients,
    createClient,
    getClient,
    updateClient,
    deleteClient,
    saveSettings,
    getSettings,
    saveRedirects,
    getRedirects,
    saveProfile,
    getProfile
}